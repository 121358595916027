import { createSlice } from '@reduxjs/toolkit';

export const bookingSlice = createSlice({
    name: 'booking',
    initialState: {
        username: '',
        email:'',
        Day1TicketsAmount: 0,
        Day2TicketsAmount: 0,
        Day3TicketsAmount: 0,
        AllDaysTicketsAmount: 0,
        SubscribedForFurtherConcertInfos: false,
        isBooked:false,
        message:''
    },
    reducers: {
        booked: (state, action) => {
            return {
                ...state, ...{
                    isBooked: action.payload.isBooked,
                    message: action.payload.message,
                    username: '',
                    email:'',
                    Day1TicketsAmount: 0,
                    Day2TicketsAmount: 0,
                    Day3TicketsAmount: 0,
                    AllDaysTicketsAmount: 0,
                    SubscribedForFurtherConcertInfos: false
                }
            }
        }
    }
});

export const { booked } = bookingSlice.actions;

export default bookingSlice.reducer;
