import React, { useEffect } from 'react';
import $ from 'jquery'; 
import Header from "./Header";
import BodySection from "./BodySection";
import Footer from "./Footer";
import WOW from 'wow.js';

const MainPage = () => {

    useEffect(() => {
        $(window).on('load', function(event) {
            $('#preloader').delay(500).fadeOut(500);
        });
        
        $(window).on('scroll', function(event) {    
            var scroll = $(window).scrollTop();
            if (scroll < 20) {
                $(".navigation").removeClass("sticky");
            } else{
                $(".navigation").addClass("sticky");
            }
        });

        /* countdown timer */
        // Load the jQuery Countdown plugin
        require('jquery-countdown');

        // Use the countdown function
        $('#clock').countdown('2024/11/23',function(event){
            var $this=$(this).html(event.strftime(''
            +'<div class="time-entry days"><span>%-D</span> Tage</div> '
            +'<div class="time-entry hours"><span>%H</span> Stunden</div> '
            +'<div class="time-entry minutes"><span>%M</span> Minuten</div> '
            +'<div class="time-entry seconds"><span>%S</span> Sekunden</div> '));
        });
    
          /* WOW Scroll Spy ==============================*/
         var wow = new WOW({
          //disabled for mobile
            mobile: false
        });
        wow.init();


        // Show or hide the sticky footer button
        $(window).on('scroll', function(event) {
            if($(this).scrollTop() > 900){
                $('.back-to-top').fadeIn(200)
            } else{
                $('.back-to-top').fadeOut(200)
            }
        });
        
        // Clean up event listeners when the component unmounts
        return () => {
          $(window).off('load');
          // ... remove other event listeners ...
        };
      }, []);
    return (
    <>
        <Header/>
        <BodySection/>
        <Footer/>
    </>
    );
};
export default MainPage