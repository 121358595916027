import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainPage  from "./components/MainPage";

const App = () => {

  return (
  <BrowserRouter>
    <Routes>
        <Route exact path = '/' element = {<MainPage/>}/>
        <Route path='*' element={<h2>Page not found!</h2>}/>
      </Routes>
  </BrowserRouter>  
  );
}
export default App;
