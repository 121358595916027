import { useState,useEffect } from "react";
import { Form, Button, FormControl, InputGroup, Modal } from "react-bootstrap"
import { useDispatch, useSelector } from 'react-redux';
import { BookTicket } from "../services/booking.js";
import { ColorRing } from  'react-loader-spinner'

const TicketSubscription = ()=>{

    const dispatch = useDispatch();
    const data = useSelector(state => state.bookingSlice);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [firstName,setFirstName] = useState('');
    const [lastName,setLastName] = useState('');
    const [email,setEmail] = useState('');
    const [Day1TicketsAmount, setDay1ticketamount] = useState(0);
    const [Day2TicketsAmount, setDay2ticketamount] = useState(0);
    const [Day3TicketsAmount, setDay3ticketamount] = useState(0);
    const [AllDaysTicketsAmount, setAllDaysTicketsAmount] =  useState(0);
    const [SubscribedForFurtherConcertInfos, setSubscribedForFurtherConcertInfos] = useState(false);
    const[opacity,setOpacity] = useState(1);
    const[confirmationMessage,setConfirmationMessage] = useState('');
    const[confirmationMessageTitle,setConfirmationMessageTitle] = useState('');

    const dayTicketPrice = 15; // Euro per day ticket
    const groupTicketPrice = 35; // Euro per group ticket
    const totalCost = (
    Day1TicketsAmount * dayTicketPrice +
    Day2TicketsAmount * dayTicketPrice +
    Day3TicketsAmount * dayTicketPrice +
    AllDaysTicketsAmount * groupTicketPrice
    ).toFixed(2);

    useEffect(()=>{
        if(data.message !== null && data.message.trim() !== ''){
            if(data.isBooked){
                setConfirmationMessageTitle("Reservierungsbestätigung");
                setConfirmationMessage(data.message);
            }else{
                setConfirmationMessageTitle("Fehler bei der Reservierung");
                setConfirmationMessage(data.message);
            }
            setLoading(false);  
            setOpacity(1);
            setShowModal(true); 
            resetForm();  
        } 
      },[data]);

      const toggleModal = () => {
        setShowModal(!showModal);
      };

    const resetForm = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setDay1ticketamount(0);
        setDay2ticketamount(0);
        setDay3ticketamount(0);
        setAllDaysTicketsAmount(0);
        setSubscribedForFurtherConcertInfos(false);
    };

    const handleSubmit = event => {
        event.preventDefault();
        setOpacity(0.5);
        setLoading(true);
        try {
            BookTicket(dispatch, {
            firstName,
            lastName,
            email,
            Day1TicketsAmount,
            Day2TicketsAmount,
            Day3TicketsAmount,
            AllDaysTicketsAmount,
            SubscribedForFurtherConcertInfos,
      });
    } catch (error) {
      console.error("Error booking tickets:", error);
      setLoading(false); 
      setOpacity(1);
    }
    };
    return <>
        <div className="col-lg-8 col-md-12 col-xs-12">
            <div className="container-form wow fadeInLeft" data-wow-delay="0.2s">
                <div className="form-wrapper">
                <div className='loadingSpinnerDivInForm'>
            <ColorRing
                visible={loading}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperclassName="blocks-wrapper"
                colors={['#852280', '#D02106', '#D83C3A', '#FE0000', '#F67F00']}
                />
        </div>
                    <Form onSubmit={handleSubmit} style={{opacity: opacity}} >
                    <div className="row mb-3">
                    <div className="col-md-6 mb-3">
                        <FormControl className="form-control" placeholder='Vorname' value={firstName}
                            onChange={event => setFirstName(event.target.value)} />
                    </div>
                    <div className="col-md-6">
                        <FormControl className="form-control" placeholder='Nachname' value={lastName}
                            onChange={event => setLastName(event.target.value)} />
                    </div>
                </div>
                        <InputGroup className='mb-3'>
                            <FormControl placeholder='E-Mail' value={email}
                            onChange={event=>setEmail(event.target.value)}></FormControl>
                        </InputGroup>
                        <div className="mb-3">
                            <div className="row">
                                <div className="col-md-4">
                                <InputGroup.Text className="input-text-below">Samstag : 23.11.2024</InputGroup.Text>
                                    <InputGroup className="custom-input-group">
                                            <Button variant="outline-secondary custom-tickets-button" style = {{marginTop:'6px'}} onClick={() => setDay1ticketamount(prevAmount => Math.max(0, prevAmount - 1))}>
                                                <i className="lni lni-minus"></i>
                                            </Button>
                                            <FormControl readOnly  type='text' value={Day1TicketsAmount} style = {{textAlign:'center'}} onChange={event => setDay1ticketamount(event.target.value)} />
                                            <Button variant="outline-secondary custom-tickets-button" style = {{marginLeft:'3px',marginTop:'6px'}} onClick={() => setDay1ticketamount(prevAmount => prevAmount + 1)}>
                                                <i className="lni lni-plus"></i>
                                            </Button>
                                    </InputGroup>
                                </div>
                                <div className="col-md-4">
                                <InputGroup.Text className="input-text-below">Sonntag : 24.11.2024</InputGroup.Text>
                                    <InputGroup className="custom-input-group">
                                            <Button variant="outline-secondary custom-tickets-button" style = {{marginTop:'6px'}} onClick={() => setDay2ticketamount(prevAmount => Math.max(0, prevAmount - 1))}>
                                                <i className="lni lni-minus"></i>
                                            </Button>
                                            <FormControl readOnly  type='text' value={Day2TicketsAmount} style = {{textAlign:'center'}} onChange={event => setDay2ticketamount(event.target.value)} />
                                            <Button variant="outline-secondary custom-tickets-button" style = {{marginLeft:'3px',marginTop:'6px'}} onClick={() => setDay2ticketamount(prevAmount => prevAmount + 1)}>
                                                <i className="lni lni-plus"></i>
                                            </Button>
                                    </InputGroup>
                                </div>
                                <div className="col-md-4">
                                <InputGroup.Text className="input-text-below">Monntag : 25.11.2024</InputGroup.Text>
                                    <InputGroup className="custom-input-group">
                                            <Button variant="outline-secondary custom-tickets-button" style = {{marginTop:'6px'}} onClick={() => setDay3ticketamount(prevAmount => Math.max(0, prevAmount - 1))}>
                                                <i className="lni lni-minus"></i>
                                            </Button>
                                            <FormControl readOnly  type='text' value={Day3TicketsAmount} style = {{textAlign:'center'}} onChange={event => setDay3ticketamount(event.target.value)} />
                                            <Button variant="outline-secondary custom-tickets-button" style = {{marginLeft:'3px',marginTop:'6px'}} onClick={() => setDay3ticketamount(prevAmount => prevAmount + 1)}>
                                                <i className="lni lni-plus"></i>
                                            </Button>
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                <div className="mb-3">
                    <Form.Label>Kombiticket (gültig für alle Tage)</Form.Label>
                    <InputGroup className="custom-input-group">
                        <Button variant="outline-secondary custom-tickets-button" style = {{marginTop:'6px'}} onClick={() => setAllDaysTicketsAmount(prevAmount => Math.max(0, prevAmount - 1))}>
                            <i className="lni lni-minus"></i>
                        </Button>
                        <FormControl readOnly  type='text' value={AllDaysTicketsAmount} style = {{textAlign:'center'}} onChange={event => setAllDaysTicketsAmount(event.target.value)} />
                        <Button variant="outline-secondary custom-tickets-button" style = {{marginLeft:'3px',marginTop:'6px'}} onClick={() => setAllDaysTicketsAmount(prevAmount => prevAmount + 1)}>
                            <i className="lni lni-plus"></i>
                        </Button>
                    </InputGroup>
                </div>
                        <Form.Group controlId="subscribeCheckbox">
                        <Form.Check style = {{margin:'15px auto auto 5px',color: '#676767',fontSize: '14px'}}
                            type="checkbox"
                            label="Abonnieren Sie weitere Konzertinformationen"
                            checked={SubscribedForFurtherConcertInfos}
                            onChange={event => setSubscribedForFurtherConcertInfos(event.target.checked)}
                        />
                    </Form.Group>
                    <div className="mb-3 ticketAmountSection">
                        <Form.Label>Total Cost</Form.Label>
                        <div>{totalCost} Euro</div>
                    </div>
                        <Button type = 'submit' variant = 'success' style = {{margin:'15px auto 5px',display:'block',width:'12rem'}} className="btn btn-common"
                        disabled  = {firstName.length <=0 || lastName.length <=0 || email.length <= 0 || Day1TicketsAmount < 0
                            || Day2TicketsAmount < 0 || Day3TicketsAmount < 0 || AllDaysTicketsAmount< 0 
                            ||(Day1TicketsAmount == 0
                            && Day2TicketsAmount == 0 && Day3TicketsAmount == 0 && AllDaysTicketsAmount== 0)}>
                           <i className="fa fa-paper-plane" aria-hidden="true">Jetzt reservieren</i>
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
        <Modal show={showModal} onHide={toggleModal}>
            <Modal.Header closeButton>
                <Modal.Title>{confirmationMessageTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{confirmationMessage}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" className="btn btn-common" onClick={toggleModal}>
                    OK
                </Button>
            </Modal.Footer>
      </Modal>
    </>
};

export default TicketSubscription;