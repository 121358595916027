import React, { useState } from 'react';

const ImageZoom = ({ imageUrl }) => {
  const [zoomed, setZoomed] = useState(false);

  const handleImageClick = () => {
    setZoomed(!zoomed);
  };

  return (
    <div className={`gallery-box ${zoomed ? 'zoomed' : ''}`}>
      <div className="img-thumb" onClick={handleImageClick}>
        <img className="img-fluid" src={imageUrl} alt="tr" />
        {zoomed && (
          <div className="zoomed-image">
            <img className="img-fluid" src={imageUrl} alt="tr" />
          </div>
        )}
      </div>
      <div className="overlay-box text-center" onClick={handleImageClick}>
        <i className="lni-plus"></i>
      </div>
    </div>
  );
};

export default ImageZoom;