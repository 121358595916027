import React, { useState } from 'react';
import CarouselComponent from './CarouselComponent'
import 'bootstrap/dist/css/bootstrap.min.css';

const Header = () => {
    const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(false);
    const [isTogglerActive, setIsTogglerActive] = useState(false);
    const [activeNavItem, setActiveNavItem] = useState('Home');

    const handleNavbarItemClick = (item) => {
      setIsNavbarCollapsed(false);
      setIsTogglerActive(false);
      setActiveNavItem(item);
    };
  
    const handleTogglerClick = () => {
      setIsTogglerActive((prevState) => !prevState);
      setIsNavbarCollapsed((prevState) => !prevState);
    };
  
    
    return <header id="Home">
    <div className="navigation">
        <div className="container">
        <nav className={`navbar navbar-expand-lg`}>
      <button className={`navbar-toggler ${isTogglerActive ? 'active' : ''}`} type="button" onClick={handleTogglerClick}>
        <span className="toggler-icon"></span>
        <span className="toggler-icon"></span>
        <span className="toggler-icon"></span>
      </button>

      <div className={`collapse navbar-collapse ${isNavbarCollapsed ? 'show' : ''}`} id="navbarSupportedContent">
      <ul className="navbar-nav ml-auto">
      <li className={`nav-item ${activeNavItem === 'Home' ? 'active' : ''}`}>
        <a className="page-scroll" href="#Home" onClick={() => handleNavbarItemClick('Home')}>
          Home
        </a>
      </li>
      <li className={`nav-item ${activeNavItem === 'Über uns' ? 'active' : ''}`}>
        <a className="page-scroll" href="#ÜberDasFestival" onClick={() => handleNavbarItemClick('Über uns')}>
         Über Das Festival
        </a>
      </li>
      <li className={`nav-item ${activeNavItem === 'Zeitpläne' ? 'active' : ''}`}>
        <a className="page-scroll" href="#Konzerte" onClick={() => handleNavbarItemClick('Zeitpläne')}>
          Konzerte
        </a>
      </li>
      <li className={`nav-item ${activeNavItem === 'Künstler' ? 'active' : ''}`}>
        <a className="page-scroll" href="#Künstler*Innen" onClick={() => handleNavbarItemClick('Künstler')}>
          Künstler*Innen
        </a>
      </li>
      <li className={`nav-item ${activeNavItem === 'Galerie' ? 'active' : ''}`}>
        <a className="page-scroll" href="#Galerie" onClick={() => handleNavbarItemClick('Galerie')}>
          Galerie
        </a>
      </li>
      <li className={`nav-item ${activeNavItem === 'Tickets' ? 'active' : ''}`}>
        <a className="page-scroll" href="#Tickets" onClick={() => handleNavbarItemClick('Tickets')}>
          Tickets
        </a>
      </li>
      <li className={`nav-item ${activeNavItem === 'Kontakt' ? 'active' : ''}`}>
        <a className="page-scroll" href="#Kontakt" onClick={() => handleNavbarItemClick('Kontakt')}>
          Kontakt
        </a>
      </li>
    </ul>
      </div>
    </nav>
        </div>
    </div>
    
<CarouselComponent/>
</header>
};

export default Header;
