import axios from 'axios';
import { booked } from '../app/bookingSlice';

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/Booking`,
});

export const BookTicket = async (dispatch, credentials) => {
    try
    {
        const { data } = await axiosInstance.post('/book',credentials);
        dispatch(booked(data));
    }
    catch 
    {
        const errorData = {
            isBooked: false,
            message: 'Es tut uns leid, aber es gab ein technisches Problem bei der Reservierung. Bitte versuchen Sie es später erneut oder kontaktieren Sie unseren Kundensupport für Unterstützung.',
          };
        dispatch(booked(errorData));
    }
}