import React, { useState } from 'react';
import galleryImg1 from '../img/gallery/img-1.JPG'
import galleryImg2 from '../img/gallery/img-2.JPG'
import galleryImg3 from '../img/gallery/img-3.JPG'
import galleryImg4 from '../img/gallery/img-4.JPG'
import galleryImg5 from '../img/gallery/img-5.JPG'
import galleryImg6 from '../img/gallery/img-6.JPG'
import teamImg1 from '../img/team/team-01.JPG'
import teamImg2 from '../img/team/team-02.JPG'
import teamImg3 from '../img/team/team-03.JPG'
import teamImg4 from '../img/team/team-04.JPG'
import teamImg5 from '../img/team/team-05.JPG'
import teamImg6 from '../img/team/team-06.JPG'
import teamImg7 from '../img/team/team-07.JPG'
import teamImg8 from '../img/team/team-08.JPG'
import aboutImg from '../img/about/about.jpg'
import TicketSubscription from './TicketSubscription';
import ImageZoom from './ImageZoom'
import ModalComponent from './ModalComponent'

const BodySection = () => {

    const [showModal, setShowModal] = useState(false);
    const [selectedContent, setSelectedContent] = useState(null);
    const modalContent = [
        {
          image: teamImg1,
          title:(
            <>
                <div className="info-text">
                    <h3>Diana Sahakyan</h3>
                    <p>Klavier</p>
                </div>
            </>
          ),
          maincontent: (
            <>
                <p>Die in Armenien geborene Pianistin Diana Sahakyan kann auf eine
                    bemerkenswerte musikalische Reise zurückblicken.
                    Ihre Leidenschaft für die Musik führte sie zu einem Studium am Staatlichen
                    Konservatorium in Eriwan, wo sie ihre Fähigkeiten vertiefte. Ihr Streben nach
                    Exzellenz trieb sie jedoch weiter, und so setzte sie ihr Studium an der
                    renommierten Hochschule für Musik und Darstellende Kunst in Frankfurt am
                    Main fort. Unter der Anleitung herausragender Professoren wie Lev Natochenny,
                    Catherine Vickers und Oliver Kern verfeinerte sie ihre Kunst.</p>
                <p className='middle-p'>Die Bühne wurde zu ihrem Zuhause, und dort erntete sie zahlreiche
                    Auszeichnungen als Anerkennung für ihr außergewöhnliches Talent. Preise von
                    internationalen Wettbewerben wie dem "Concours international Flame" in
                    Frankreich, dem "Klaviersommer Cochem" in Deutschland und der "Nuova Coppa
                    Pianisti" in Italien schmücken ihre Karriere.</p>
                <p>Ihre künstlerische Entdeckerfreude fand Ausdruck in ihrem Debütalbum "Das
                    Jahr", das Werke von Fanny Mendelssohn Hensel umfasst und 2022 unter dem
                    deutschen Musiklabel "Kaleidos Musikeditionen" veröffentlicht wurde. Dieses
                    Album, unterstützt vom Deutschen Musikrat und dem Kulturamt Frankfurt,
                    würdigt weniger bekannte, aber herausragende zeitgenössische Komponistinnen.
                    Ihr zweites Album "Femmes de Légende", das Werke der französischen
                    Komponistin Mel Bonis präsentiert, wurde im September 2022 ebenfalls bei
                    "Kaleidos Musikeditionen" veröffentlicht.</p>
            </>
          ),
        },
        {
            image: teamImg2,
            title:(
              <>
                  <div className="info-text">
                      <h3>Hans Christian Aavik</h3>
                      <p>Violine</p>
                  </div>
              </>
            ),
            maincontent: (
              <>
                  <p>1st prize winner of the 2022 Carl Nielsen International Competition and recipient of the
                    2022 Usedom Prize, Hans Christian Aavik "combines a probing intellect and curiosity with
                    an extroverted charisma that has made him one of Europe’s most exciting young talents.”
                    (Bachtrack.com).</p>
                    <p className='middle-p'>Following his success at the Carl Nielsen Competition - which additionally included Prize
                    for Best Interpretation and the Odense Symphony Orchestra Prize - Hans Christian has
                    been invited to return to Denmark to record his first orchestral album on Orchid Classics.
                    His debut album “AETERNUS” with pianist Karolina Aavik, was released in 2021 at the
                    Arvo Pärt Centre in Tallinn, featuring works by Bach, Schubert and Pärt.</p>
                    <p className='middle-p'><b>In 2023 Hans Christian Aavik is featured together with pianist Karolina Aavik in the
                    new Deutsche Grammophon landmark series “Rising Stars” on STAGE+.</b></p>
                    <p className='middle-p'>Already well established as a soloist at home in Estonia, Hans Christian Aavik made his
                    solo debut at the 2022 Pärnu Music Festival and performs regularly with orchestras
                    including Estonian National Symphony Orchestra,Tallinn Chamber Orchestra etc.
                    Internationally he has performed with the Copenhagen Philharmonic and Stuttgart
                    Chamber Orchestra.</p>
                    <p className='middle-p'>In 2022 he was awarded the Annual Award of the Cultural Endowment of Estonia and
                    received additionally the “Musician of the year” title by Association of Estonian
                    Professional Musicians.</p>
                    <p className='middle-p'>In the season 2023/24 he has been invited to perform as a soloist and chamber musician
                    in Germany, Israel, France, England, Estonia and Sweden.</p>
                    <p className='middle-p'>Born in Tallinn in 1998, Hans Christian started his violin studies at the age of five. in 2017
                    he moved to Germany to study at the Frankfurt Music Academy of Music and Arts under
                    the tutelage of Prof. Erik Schumann and chamber music with Prof. Angelika Merkle. Since
                    2021 he studied alongside at the Music and Arts Private University of Vienna with Julian
                    Rachlin and chamber music with Prof. Evgeny Sinaiski.</p>
                    <p className='middle-p'><b>As of October 2022 Hans Christian Aavik is studying as a young soloist at Kronberg
                    Academy with Erik Schumann. These studies are funded by the Dr Ratjen-Patronat.</b></p>
                    <p className='middle-p'>He has benefited from additional guidance from Christoph Eschenbach, Kirill Gerstein,
                    Augustin Hadelich, Steven Isserlis, Sophia Rahman, Sir András Schiff, Enrico Pace,
                    Antoine Tamestit, Gerhard Schulz, prof. Kolja Blacher, Mi-Kyung Lee, Dénes Várjon and
                    has worked with conductors such as Nikolaj Szeps-Znaider, Olari Elts, Ville Matvejeff,
                    Risto Joost, Arvo Volmer and many others.</p>
                    <p><b>Since 2023 Hans Christian Aavik is one of the active scholarship holders at the arteMusica
                    foundation for music and culture. He is from 2022 also one of the active scholarship holders at the Villa Musica Rheinland-Pfalz.
                    Since 2019, he has been supported by Yehudi Menuhin Live Music Now Frankfurt am Main e.V.
                    On the generous permission of the Estonian Instrument Foundation and the Sapožnin family,
                    Hans Christian Aavik currently plays on a Giovanni Paolo Maggini violin (made ca 1610 in
                    Brescia, Italy) and on bow made by Victor Fétique (made ca 1930 in France).</b></p>
              </>
            ),
          },
          {
            image: teamImg3,
            title:(
              <>
                  <div className="info-text">
                      <h3>Miho Kawai</h3>
                      <p>Bratsche</p>
                  </div>
              </>
            ),
            maincontent: (
              <>
                  <p>Miho Kawai (*1993) wurde in Hiroshima, Japan geboren. Sie begann mit fünf
                    Jahren die Geige zu spielen und im Alter von 16 Jahren fand sie ihren
                    Lebensinhalt: die Bratsche.</p>
                  <p className='middle-p'>Nach Abschluss ihres Bachelorstudiums an der Toho Gakuen University of
                    Music in Tokio studierte sie die Bratsche bei Nobuko Imai und Wenting Kang
                    sowie Kammermusik bei Heime Müller an der Escuela Superior de Música
                    Reina Sofía in Madrid. Dort wurde sie mit dem Sobresaliente-Preis
                    ausgezeichnet. 2017 zog sie nach Frankfurt am Main, um ihr Studium bei
                    Ingrid Zur an der HfMDK Frankfurt fortzusetzen; sie schloss 2020 ihr
                    Masterstudium ab.</p>
                  <p>Von 2019 bis 2021 spielte sie mit einem Zeitvertrag beim Frankfurter Opern-
                    und Museumsorchester. Sie ist als Gast-Solo-Bratscherin bei der
                    Mecklenburgischen Staatskapelle Schwerin eingeladen. Als Solistin und
                    Kammermusikerin widmet sie sich Kooperationsprojekten. Sie hat bisher mit
                    Chören wie dem Frankfurter Kammerchor und dem Kammerchor Rhein-Main
                    sowie mit den Tanzensembles wie dem Westdeutschen Tanztheater
                    Düsseldorf zusammengearbeitet. Seit 2019 spielt sie auf einer Bratsche von
                    Ritz Iwata aus Amsterdam.</p>
              </>
            ),
          },
          {
            image: teamImg4,
            title:(
              <>
                  <div className="info-text">
                      <h3>Dima Udovychenko</h3>
                      <p>Violine</p>
                  </div>
              </>
            ),
            maincontent: (
              <>
                  <p>Dmytro Udovychenco, geboren 1999, nimmt seit dem fünften Lebensjahr
                    Musikunterricht. Im Alter von sechs Jahren besuchte er das Kharkiv-Internat
                    mit musikalischem Schwerpunkt, wo er Violinunterricht bei Ludmilla Varenina
                    erhielt.</p>
                  <p className='middle-p'>Dmytro Udovychenco gewann mehrere Wettbewerbe, unter anderem belegte
                    er beim internationalen Wettbewerb „Accords of Khortytsa“ im März 2010 den
                    ersten Platz. Zudem gewann Dmitry 2017 den 2. Preis beim International
                    Jascha Heifetz Wettbewerb in Vilnius (Litauen) und 2018 gewann er den
                    ersten Preis beim internationalen Violinwettbewerb Andrea Postacchini in
                    Fermo (Italien) den 2. Preis beim I international Odessa Violinwettbewerb in
                    Odessa (Ukraine) sowie den 2. Preis beim Internationalen Joseph Joachim
                    Violinwettbewerb in Hannover. 2021 wurde ihm beim International Odessa
                    Violinwettbewerb der erste Preis zugesprochen.</p>
                  <p>Zuletzt studierte Dmytro Udovychenco an der Folkwang-Universität der
                    Künste bei Prof. Boris Garlitsky. 2017 nahm er als aktiver Schüler von Ana
                    Chumachenco an den Geigen Meisterkursen in Kronberg teil, wo er mit dem
                    Prinz von Hessen-Preis ausgezeichnet wurde. Seit Oktober 2022 studiert er an
                    der Kronberg Academy bei Christian Tetzlaff.</p>
              </>
            ),
          },
          {
            image: teamImg5,
            title:(
              <>
                  <div className="info-text">
                      <h3>Karolina Aavik</h3>
                      <p>Klavier</p>
                  </div>
              </>
            ),
            maincontent: (
              <>
                  <p>Karolina Aavik ist eine gefeierte estnische Pianistin, die in diesem Jahr in der neuen "Rising
                    Stars"-Serie der Deutschen Grammophon auf STAGE+ zu hören ist. Sie widmet sich
                    hauptsächlich der Kammermusik und konzertiert regelmäßig in ihrer Heimat und in ganz Europa.</p>
                    <p className='middle-p'>Karolina Aaviks Debütalbum "A E T E R N U S" konzentriert sich auf Werke von Bach, Schubert
                    und Pärt. Aufgenommen mit dem Geiger Hans Christian Aavik, wurde das Album 2021 im Arvo
                    Pärt Centre in Tallinn vorgestellt.</p>
                    <p className='middle-p'>
                    Für die Reihe "Rising Stars" der Deutschen Grammophon nahm das Duo die Violinsonate Nr. 3
                    von George Enescu sowie Werke der zeitgenössischen amerikanischen und estnischen
                    Komponisten Morten Lauridsen und Eduard Oja auf. Estnische Musik wird regelmäßig in
                    Karolinas Konzertprogrammen aufgeführt und stand im Mittelpunkt eines der beiden Konzerte,
                    die sie mit Hans Christian Aavik beim Usedomer Festival 2022 in Deutschland gab.
                    In den Jahren 2023 und 2024 werden sie im Rahmen einer Residency für die Estnische

                    Kulturstiftung mehrere neue Werke uraufführen, darunter ein eigens für sie geschriebenes Violin-
                    und Klavierkonzert von Tõnu Kõrvits.
                    </p>
                    <p className='middle-p'>Karolina Aavik, die derzeit ihr Studium an der Hochschule für Musik und Darstellende Kunst
                    Frankfurt am Main bei Prof. Angelika Merkle absolviert, erhielt in Wien weitere wertvolle
                    Anregungen von Prof. Christoph Eggner und Prof. Jevgueni Sinaiski. Sie wurde auch stark von
                    Sten Lassmann, Sophia Rahman, Erik Schumann, Steven Isserlis, Antoine Tamestit und vielen
                    anderen beeinflusst.</p>
                    <p className='middle-p'>Karolina Aavik ist Preisträgerin mehrerer Wettbewerbe sowohl als Solistin als auch als
                    Kammermusikerin, darunter "Polytechnische Gesellschaft Frankfurt", "Ilmari Hannikainen Piano
                    Chamber Music Competition", "VII International Stasis Vainiunas Competition".</p>
                    <p><b>Seit 2019 wird Karolina Aavik von Yehudi Menuhin Live Music Now Frankfurt am Main unterstützt.</b></p>
              </>
            ),
          },
          {
            image: teamImg6,
            title:(
              <>
                  <div className="info-text">
                      <h3>Frederick Winterson</h3>
                      <p>Cello</p>
                  </div>
              </>
            ),
            maincontent: (
              <>
                  <p>Frederick Winterson wurde in eine musikalische Familie englischer und
                    deutscher Eltern geboren. 2017 absolvierte er an der Hochschule für Musik
                    und darstellende Kunst Frankfurt am Main sein Bachelor-Studium mit
                    Auszeichnung, wo er bei Bonian Tian (Solo-Cellist des Gürzenich-Orchesters
                    Köln) studierte. 2019 folgte sein Master of Performance an der Guildhall
                    School of Music and Drama in London als Student von Louise Hopkins, und als
                    Stipendiat des „Child Memorial-Award“. Frederick wurde seitdem zum Junior
                    Fellow der Schule ernannt. Sein an der Guildhall School gegründetes
                    Klaviertrio ‘Ìgnis’ gewann 2018 sowohl den St. James Piano-Prize, als auch den
                    Ivan Sutton-Wettbewerb.</p>
                  <p className='middle-p'>In Deutschland erspielte Frederick sich 1. Preise bei Jugend musiziert und
                    dem Kammermusikwettbewerb Köln. 2016 erhielt er einen Sonderpreis an der
                    Sommerakademie Schloss Heiligenberg und ihm wurde anschließend von der
                    Kallioppe-Stiftung ein Cellobogen von Heinrich Knopf zur Verfügung gestellt.
                    Frederick hat an renommierten Konzertorten wie dem Barbican, der
                    Wigmore-Hall, der Kölner Philharmonie, der Alten Oper Frankfurt, dem
                    Gasteig München und der Tonhalle Düsseldorf gespielt.</p>
              </>
            ),
          },          {
            image: teamImg7,
            title:(
              <>
                  <div className="info-text">
                      <h3>Josephine Bastian</h3>
                      <p>Cello</p>
                  </div>
              </>
            ),
            maincontent: (
              <>
                  <p>Josephine Bastian, 1995 in Berlin geboren, ist passionierte Kammer- und Orchestermusikerin, Solokünstlerin,sowie Cellolehrerin.
                    „Bastians eleganter, zarter und effektvoller Cello-Stil überzeugt in allen Passagen (…)“ , beschreibt die nmz
                    Josephines CD-Einspielung der Rokoko-Variationen von Tschaikowsky mit dem Konzerthausorchester Berlin
                    unter der Leitung von Jörg Peter Weigle.</p>
                  <p className='middle-p'>Als Solistin konzertierte Josephine bereits mit Musikern des Rundfunk-Sinfonieorchester Berlin (2006), mit dem
                    Konzerthausorchester Berlin, dem Orchester des Musikgymnasium C. Ph. E. Bach Berlin (2013/14) und trat im
                    Rahmen zahlreicher Festivals und Konzertreihen, wie dem Rheingau-Musik-Festival, Musikmesse Festival
                    Frankfurt, „Mein Lieblingsstück“ in der Alten Oper Frankfurt, „Musik på Bosjökloster“ in Südschweden und
                    „Rhapsody in School“ im Konzerthaus Berlin, an der Seite bedeutender Künstler wie Daniel Müller-Schott,
                    Alban Gerhardt, Daniel Hope, Antoine Tamestit und Lars Vogt auf.
                    Josephine wurde mehrfache Preisträgerin beim Cellowettbewerb Yehudi Menuhin e.V. Live Music Now
                    Frankfurt (2017), beim Streicher-Wettbewerb der Peter Pirazzi-Stiftung (2016), sowie Stipendiatin der
                    Frankfurter Bachkonzerte. Von 2017-2019 war Josephine Akademistin beim Rundfunk-Sinfonieorchester Berlin
                    und in 2022 Stipendiatin der Paul-Hindemith-Orchesterakademie des Frankfurter Opern-und
                    Museumsorchesters.</p>
                    <p className='middle-p'>Ihr Jung-und Bachelorstudium absolvierte Josephine zunächst an der Hochschule für Musik Hanns Eisler Berlin
                    bei Stefan Giglberger und schloss dieses 2018 in der Klasse von Professor Michael Sanderling an der
                    Hochschule für Musik und Darstellende Kunst Frankfurt am Main, mit großem Erfolg ab. Von 2019-2021
                    studierte Josephine im Master of Cello Performance-Studiengang bei Professor Brandon Vamos und dem
                    Pacifica String Quartet, an der renommierten Jacobs School of Music Bloomington (USA). Seit ihrem Abschluss
                    gab sie dort auch selbst Meisterkurse für Studierende. Weitere bedeutende künstlerische Impulse erhielt
                    Josephine auf Meisterkursen von David Geringas, Johannes Moser, Troels Svane, Wolfgang Emanuel Schmidt,
                    Wolfgang Böttcher und Richard Aaron. </p>
                    <p className='middle-p'>Als passionierte Kammermusikerin ist Josephine Bastian Gründungsmitglied des MOSAIK trio und des
                    CONCELLI quartet. Die Ensembles widmen sich intensiver Konzerttätigkeit in Deutschland und Schweden.</p>
                    <p>Josephine Bastian spielt ein italienisches Cello von Celestino Farotto (1907).</p>
              </>
            ),
          },
          {
            image: teamImg8,
            title:(
              <>
                  <div className="info-text">
                      <h3>Sona Talian</h3>
                      <p>Klavier</p>
                  </div>
              </>
            ),
            maincontent: (
              <>
                  <p>Sona Talian, entstammt einer traditionellen armenischen Musikerdynastie und ist in Eriwan
                    aufgewachsen. Mit vier Jahren trat sie erstmals öffentlich auf, in dieser Zeit entstanden auch ihre ersten
                    Klavierkompositionen.</p>
                  <p className='middle-p'>Nachdem eine Konzertreise Sona Talian 1990 nach Deutschland führte, beschloss sie ihre
                    musikalische Ausbildung hier fortzusetzen. Sie studierte Klavier und Komposition in Detmold und
                    Frankfurt, wo sie ihre Ausbildung als Pianistin mit Auszeichnung abgeschlossen hat.</p>
                    <p className='middle-p'>Nicht nur klassische Musik, auch Jazz, Rock, Folklore und Chanson spielten in Ihrer
                    künstlerischen Entwicklung eine wichtige Rolle. Ihre Musik lässt sich daher nicht ohne weiteres
                    einem bestimmten Genre zuordnen.</p>
                    <p className='middle-p'>Ihr 2010 erschienenes Debütalbum „Raindrops“ mit eigenen Kompositionen für Klavier hat
                    international viel Beachtung gefunden. Seitdem wird ihre Musik häufig für Filmproduktionen
                    genutzt und im Rundfunk gesendet.</p>
                    <p>Sona Talian lebt und arbeitet als Pianistin und Komponistin in Frankfurt am Main und konzertiert
                    regelmäßig sowohl solistisch als auch mit Ensembles im In- und Ausland.</p>
              </>
            ),
          },
        
      ];
    const openModal = (contentIndex) => {
        setSelectedContent(modalContent[contentIndex]);
        setShowModal(true);
      };
    
      const closeModal = () => {
        setShowModal(false);
        setSelectedContent(null);
      };

    const [activeTab, setActiveTab] = useState('monday'); 

    const handleTabClick = (tabId) => {
      setActiveTab(tabId);
    };

    
    return <>


        <section id="ÜberDasFestival" className="about-event section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title-header text-center">
                            <h1 className="section-title wow fadeInUp" data-wow-delay="0.2s">Über das Festival</h1>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xs-12 wow fadeInRight d-flex justify-content-center align-items-center" data-wow-delay="0.3s">
                        <img className="img-fluid" src={aboutImg} alt=""/>
                </div>
                <div className="col-md-6 col-lg-6 col-xs-12 wow fadeInLeft text-justify" data-wow-delay="0.3s">
                        <p className="intro-desc wow fadeInUp" style={{ fontSize: '21px', fontWeight: 700,marginBottom: '10px',marginTop: '10px' }} data-wow-delay="0.3s">Grußwort</p>
                        <p className="intro-desc wow fadeInUp" data-wow-delay="0.3s">Als Gründerin und künstlerische Leiterin des Komponistinnen Musikfestivals in Frankfurt am Main heiße ich Sie herzlich auf unserer Website willkommen.
                        Das KomponistInnen Musikfestival wurde im Jahr 2022 ins Leben gerufen und hat sich seitdem zu einem jährlichen Höhepunkt musikalischer Exzellenz entwickelt. Es ist weit mehr als nur eine musikalische Veranstaltung; es repräsentiert ein kulturelles Erbe, das wir in unserer Stadt Frankfurt mit Stolz bewahren und teilen. Wir sind fest davon überzeugt, dass unser Festival nicht nur die künstlerische Vielfalt in Frankfurt auf neue Weise bereichert, sondern auch einen positiven Einfluss auf die gesamte Musikszene der Stadt ausübt.
                        Wir laden Sie herzlich dazu ein, Teil dieser musikalischen Reise zu werden und gemeinsam mit uns außergewöhnliche Talente und die Vielfalt der Komponistinnenmusik zu feiern.
                        Werfen Sie einen Blick auf unser Programm und sichern Sie sich Ihre Tickets für dieses unvergessliche Erlebnis.</p>                 
                        <p className="intro-desc wow fadeInUp" data-wow-delay="0.3s" style={{ marginTop: '10px' }}>Herzlichst</p>
                        <p className="intro-desc wow fadeInUp" data-wow-delay="0.3s">Diana Sahakyan, Konzert Pianistin</p>
                    </div>
                </div>
            </div>
        </section>
        <section className="countdown-timer section-padding">
            <div className="container">
                <div className="row text-center">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="heading-count">
                            <h2 className="wow fadeInDown" data-wow-delay="0.2s">DAS FESTIVAL BEGINNT IN</h2>
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="row time-countdown justify-content-center wow fadeInUp" data-wow-delay="0.2s">
                            <div id="clock" className="time-count"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="counter-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-3 col-xs-12 work-counter-widget text-center">
                        <div className="counter wow fadeInRight" data-wow-delay="0.3s">
                            <div className="icon"><i className="lni-map"></i></div>
                            <p>Orangerie im Günthersburgpark</p>
                            <span>Frankfurt am Main</span>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 col-xs-12 work-counter-widget text-center">
                        <div className="counter wow fadeInRight" data-wow-delay="0.6s">
                            <div className="icon"><i className="lni-timer"></i></div>
                            <p>23. - 25. November, 2024</p>
                            <span>19:00 PM – 22:00 PM</span>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 col-xs-12 work-counter-widget text-center">
                        <div className="counter wow fadeInRight" data-wow-delay="0.9s">
                            <div className="icon"><i className="lni-users"></i></div>
                            <p>100 Verfügbare plätze</p>
                            <span>Reservieren sie ihr ticket unten!</span>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 col-xs-12 work-counter-widget text-center">
                        <div className="counter wow fadeInRight" data-wow-delay="1.2s">
                            <div className="icon"><i className="lni-heart"></i></div>
                            <p>Getränke & snacks</p>
                            <span>Verpassen sie es nicht</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="Konzerte" className="schedule section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title-header text-center">
                            <h1 className="section-title wow fadeInUp" data-wow-delay="0.2s">KONZERTE</h1>
                            <p>Das Festivalprogramm wird bald aktualisiert. Bitte schauen Sie später wieder vorbei.</p>
                        </div>
                    </div>
                </div>
                <div className="schedule-area row wow fadeInDown" data-wow-delay="0.3s">
                    <div className="schedule-tab-title col-md-3 col-lg-3 col-xs-12">
                        <div className="table-responsive" style={{ overflowX: 'unset' }}>
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                <a className={`nav-link ${activeTab === 'monday' ? 'active' : ''}`}
                                    id="monday-tab" data-toggle="tab"  role="tab"
                                    aria-controls="monday" aria-selected={activeTab === 'monday'} onClick={() => handleTabClick('monday')}>
                                    <div className="item-text">
                                    <h4>Freitag</h4>
                                    <h5>06 oktober</h5>
                                    </div>
                                </a>
                                </li>
                                <li className="nav-item">
                                <a className={`nav-link ${activeTab === 'tuesday' ? 'active' : ''}`}
                                    id="tuesday-tab" data-toggle="tab"  role="tab" aria-controls="tuesday"
                                    aria-selected={activeTab === 'tuesday'} onClick={() => handleTabClick('tuesday')}>
                                    <div className="item-text">
                                    <h4>Samstag</h4>
                                    <h5>07 oktober</h5>
                                    </div>
                                </a>
                                </li>
                                <li className="nav-item">
                                <a className={`nav-link ${activeTab === 'wednesday' ? 'active' : ''}`}
                                    id="wednesday-tab" data-toggle="tab"  role="tab" aria-controls="wednesday"
                                    aria-selected={activeTab === 'wednesday'} onClick={() => handleTabClick('wednesday')}>
                                    <div className="item-text">
                                    <h4>Sonntag</h4>
                                    <h5>08 oktober</h5>
                                    </div>
                                </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="schedule-tab-content col-md-9 col-lg-9 col-xs-12 clearfix">
                        <div className="tab-content" id="myTabContent">
                            <div className={`tab-pane fade ${activeTab === 'monday' ? 'active show' : ''}`} id="monday" role="tabpanel" aria-labelledby="monday-tab">
                            <div id="accordion">
                                <div className="card">
                                    <div id="headingOne">
                                        <div className="collapsed card-header">
                                            <span className="time">Beginn: 20 Uhr, Einlass: 19:30 Uhr</span>
                                            <h4>Eröffnungskonzert</h4>
                                            <p>Robert Schumann – Romanzen op. 94</p>
                                            <p>Clara Schumann – Klaviertrio op. 17, g – Moll</p>
                                            <p>Johannes Brahms – Klaviertrio n. 1, op. 8 H – Dur</p>
                                            <div className='artists'>
                                                <h5 className="name">Hans Christian Aavik, Violine</h5>
                                                <h5 className="name">Frederick Winterson, Cello</h5>
                                                <h5 className="name">Karolina Aavik, Klavier</h5>
                                                <h5 className="name">Diana Sahakyan, Klavier</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className={`tab-pane fade ${activeTab === 'tuesday' ? 'active show' : ''}`} id="tuesday" role="tabpanel" aria-labelledby="tuesday-tab">
                            <div id="accordion">
                                <div className="card">
                                    <div id="headingOne">
                                        <div className="collapsed card-header">
                                            <span className="time">Beginn: 20 Uhr, Einlass: 19:30 Uhr</span>
                                            <h4>Fête de la musique</h4>
                                            <p>Mélanie Bonis – Klavierquartett n. 2 D – Dur</p>
                                            <p>César Franck – Klavierquintett F - Moll</p>
                                            <div className='artists'>
                                                <h5 className="name">Hans Christian Aavik, Violine</h5>
                                                <h5 className="name">Dima Udovychenko, Violine</h5>
                                                <h5 className="name">Miho Kawai, Viola</h5>
                                                <h5 className="name">Frederick Winterson, Cello</h5>
                                                <h5 className="name">Diana Sahakyan, Klavier</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className={`tab-pane fade ${activeTab === 'wednesday' ? 'active show' : ''}`} id="wednesday" role="tabpanel" aria-labelledby="wednesday-tab">
                            <div id="accordion">
                                <div className="card">
                                    <div id="headingOne">
                                        <div className="collapsed card-header">
                                            <span className="time">Beginn: 18 Uhr, Einlass: 17:30 Uhr</span>
                                            <h4>Abschlusskonzert</h4>
                                            <p>S. Rachmaninoff - Trio Élégiaque n.1 g-Moll</p>
                                            <p>Sona Talian - Werke für Klavier und Streicher</p>
                                            <p>Antonin Dvorák – Klavierquintett n. 2, Op. 81 A – Dur</p>
                                            <div className='artists'>
                                            <h5 className="name">Hans Christian Aavik, Violine</h5>
                                                <h5 className="name">Dima Udovychenko, Violine</h5>
                                                <h5 className="name">Miho Kawai, Viola</h5>
                                                <h5 className="name">Frederick Winterson, Cello</h5>
                                                <h5 className="name">Diana Sahakyan, Klavier</h5>
                                                <h5 className="name">Sona Talian, Klavier</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="Künstler*Innen" className="section-padding text-center">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title-header text-center">
                            <h1 className="section-title wow fadeInUp" data-wow-delay="0.2s">Künstler*innen</h1>
                            <p>Die Liste der Künstler*innen wird bald mit den aktuellen Teilnehmern für dieses Jahr aktualisiert. Bitte schauen Sie später wieder vorbei.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="team-item wow fadeInUp" data-wow-delay="0.2s">
                            <div className="team-img">
                                <img className="img-fluid" src={teamImg1} alt=""/>
                                <div className="team-overlay">
                                    <div className="overlay-social-icon text-center">
                                        <ul className="social-icons">
                                            <li><a href="https://www.dianasahakyan.de/" target="_blank"><i className="lni-website" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="info-text">
                                <h3><a onClick={() => openModal(0)}>Diana Sahakyan</a></h3>
                                <p>Klavier</p>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="team-item wow fadeInUp" data-wow-delay="0.4s">
                            <div className="team-img">
                                <img className="img-fluid" src={teamImg2} alt=""/>
                                <div className="team-overlay">
                                    <div className="overlay-social-icon text-center">
                                        <ul className="social-icons">
                                            <li><a href="https://www.hansaavik.com/home" target="_blank"><i className="lni-website" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="info-text">
                                <h3><a onClick={() => openModal(1)}>Hans Christian Aavik</a></h3>
                                <p>Violine</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="team-item wow fadeInUp" data-wow-delay="1s">
                            <div className="team-img">
                                <img className="img-fluid" src={teamImg7} alt=""/>
                                <div className="team-overlay">
                                    <div className="overlay-social-icon text-center">
                                        <ul className="social-icons">
                                            <li><a href="https://josephine-bastian.jimdosite.com/" target="_blank"><i className="lni-website" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="info-text">
                                <h3><a onClick={() => openModal(6)}>Josephine Bastian</a></h3>
                                <p>Cello</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="team-item wow fadeInUp" data-wow-delay="0.6s">
                            <div className="team-img">
                                <img className="img-fluid" src={teamImg3} alt=""/>
                                <div className="team-overlay">
                                    <div className="overlay-social-icon text-center">
                                        <ul className="social-icons">
                                            <li><a href="https://www.mihokawaiviola.com/bio" target="_blank"><i className="lni-website" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="info-text">
                                <h3><a onClick={() => openModal(2)}>Miho Kawai</a></h3>
                                <p>Bratsche</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="team-item wow fadeInUp" data-wow-delay="1s">
                            <div className="team-img">
                                <img className="img-fluid" src={teamImg4} alt=""/>
                                <div className="team-overlay">
                                    <div className="overlay-social-icon text-center">
                                        <ul className="social-icons">
                                            <li><a href="#"><i className="lni-website" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="info-text">
                                <h3><a onClick={() => openModal(3)}>Dima Udovychenko</a></h3>
                                <p>Violine</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="team-item wow fadeInUp" data-wow-delay="0.8s">
                            <div className="team-img">
                                <img className="img-fluid" src={teamImg5} alt=""/>
                                <div className="team-overlay">
                                    <div className="overlay-social-icon text-center">
                                        <ul className="social-icons">
                                            <li><a href="https://www.karolina-aavik.com/" target="_blank"><i className="lni-website" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="info-text">
                                <h3><a onClick={() => openModal(4)}>Karolina Aavik</a></h3>
                                <p>Klavier</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="team-item wow fadeInUp" data-wow-delay="1s">
                            <div className="team-img">
                                <img className="img-fluid" src={teamImg6} alt=""/>
                                <div className="team-overlay">
                                    <div className="overlay-social-icon text-center">
                                        <ul className="social-icons">
                                            <li><a><i className="lni-website" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="info-text">
                                <h3><a onClick={() => openModal(5)}>Frederick Winterson</a></h3>
                                <p>Cello</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="team-item wow fadeInUp" data-wow-delay="0.2s">
                            <div className="team-img">
                                <img className="img-fluid" src={teamImg8} alt=""/>
                                <div className="team-overlay">
                                    <div className="overlay-social-icon text-center">
                                        <ul className="social-icons">
                                            <li><a><i className="lni-website" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="info-text">
                                <h3><a onClick={() => openModal(7)}>Sona Talian</a></h3>
                                <p>Klavier</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <ModalComponent showModal={showModal} closeModal={closeModal} content={selectedContent} />
        </section>
        <section id="Galerie" className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title-header text-center">
                            <h1 className="section-title wow fadeInUp" data-wow-delay="0.2s">Festival-Galerie</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-6 col-lg-4 galleryBoxCentered">
                    <ImageZoom imageUrl={galleryImg1} />
                    </div>
                    <div className="ccol-md-6 col-sm-6 col-lg-4 galleryBoxCentered">
                    <ImageZoom imageUrl={galleryImg2} />
                    </div>
                    <div className="ccol-md-6 col-sm-6 col-lg-4 galleryBoxCentered">
                    <ImageZoom imageUrl={galleryImg3} />
                    </div>
                    <div className="ccol-md-6 col-sm-6 col-lg-4 galleryBoxCentered">
                    <ImageZoom imageUrl={galleryImg4} />
                    </div>
                    <div className="ccol-md-6 col-sm-6 col-lg-4 galleryBoxCentered">
                    <ImageZoom imageUrl={galleryImg5} />
                    </div>
                    <div className="ccol-md-6 col-sm-6 col-lg-4 galleryBoxCentered">
                    <ImageZoom imageUrl={galleryImg6} />
                    </div>
                </div>
            </div>
        </section>
        <section id="Tickets" className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title-header text-center">
                            <h1 className="section-title wow fadeInUp" data-wow-delay="0.2s">Tickets</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-sm-6 col-xa-12 mb-3">
                        <div className="price-block-wrapper wow fadeInLeft" data-wow-delay="0.2s">
                            <div className="icon">
                                <i className="lni-ticket"></i>
                            </div>
                            <div className="colmun-title">
                                <h5>Ticket Normalpreis</h5>
                            </div>
                            <div className="price">
                                <h2>€15</h2>
                            </div>
                            <a href="#Ticketreservierung" className="btn btn-common">Ticket Reservieren</a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-xa-12 mb-3">
                        <div className="price-block-wrapper wow fadeInUp" data-wow-delay="0.3s">
                            <div className="icon">
                                <i className="lni-ticket"></i>
                            </div>
                            <div className="colmun-title">
                                <h5>Ticket Ermäßigt</h5>
                            </div>
                            <div className="price">
                                <h2>€10</h2>
                            </div>
                            <a href="#Ticketreservierung" className="btn btn-common">Ticket Reservieren</a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-xa-12 mb-3">
                        <div className="price-block-wrapper wow fadeInRight" data-wow-delay="0.4s">
                            <div className="icon">
                                <i className="lni-ticket"></i>
                            </div>
                            <div className="colmun-title">
                                <h5>Kombiticket</h5>
                            </div>
                            <div className="price">
                                <h2>€35</h2>
                            </div>
                            <a href="#Ticketreservierung" className="btn btn-common">Ticket Reservieren</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="Ticketreservierung" className="section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title-header text-center">
                            <h1 className="section-title wow fadeInUp" data-wow-delay="0.2s">Ticketreservierung</h1>
                        </div>
                    </div>
                        <TicketSubscription/>
                </div>
            </div>
        </section>
        <section id="Kontakt">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <meta name="robots" content="noindex, nofollow" />
                        <object style={{ border: '0', height: '450px', width: '100%' }} data="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2557.668828225911!2d8.704975396511683!3d50.12991871213213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd0fc43eaab613%3A0x21839f3d431ee7db!2sOrangerie!5e0!3m2!1sen!2sde!4v1692725495647!5m2!1sen!2sde"></object>
                    </div>
                </div>
            </div>
        </section>
    </>
};

export default BodySection;
