import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalComponent = ({ showModal, closeModal, content }) => {
    if (!content) {
        return null;
      }
  return (
<Modal show={showModal} onHide={closeModal} size="lg">
        <Modal.Header closeButton>
            <Modal.Title>
                
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="modal-body custom-modal-body">
            <div className='row'>
                <div className="col-sm-6 col-md-12 col-lg-12">
                    <div className='row'>
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <img src={content.image} alt="" className="img-fluid" />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            {content.title}
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-12 col-lg-12">
                    <div >
                        {content.maincontent}
                    </div>
                </div>
            </div>
           
            
          </div>
        </Modal.Body>
    </Modal>
  );
};

export default ModalComponent;
