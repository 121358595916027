import React, { useEffect, useRef } from 'react';

const Footer = () => {
    const contentRef = useRef(null);

  const scrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  };
    return <>
    <footer className="footer-area section-padding">
        <div className="container">
            <div className="row">
            <div className="col-md-6 col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-delay="0.2s">
                    <h3>KONTAKT</h3>
                    <p>info@komponistinnen-musikfestival.de</p>
                </div>
                <div className="col-md-6 col-lg-3 col-sm-6 col-xs-12 wow fadeInUp" data-wow-delay="0.4s">
                    <h3>QUICK LINKS</h3>
                    <ul>
                        <li><a href="#ÜberDasFestival">Über Das Festival</a></li>
                        <li><a href="#Konzerte">Konzerte</a></li>
                        <li><a href="#Galerie">Galerie</a></li>
                        <li><a href="#Tickets">Tickets</a></li>
                    </ul>
                </div>
                <div className="col-md-6 col-lg-3 col-sm-6 col-xs-12 wow fadeInUp" data-wow-delay="0.8s">
                    <div className="widget">
                        <h3>FOLGEN SIE UNS AUF</h3>
                        <ul className="footer-social">
                            <li><a className="facebook" href="https://www.facebook.com/people/KomponistInnen-Musikfestival/100085269105698/" target="_blank"><i className="lni-facebook"></i></a></li>
                            <li><a className="twitter" href="https://www.instagram.com/komponistinnen_festival/" target="_blank"><i className="lni-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <div id="copyright">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="site-info">
                        <p>© Komponist*Innen Musikfestival 2024</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <a href="#" className="back-to-top" onClick={scrollToTop}>
        <i className="lni-chevron-up"></i>
    </a>
    </>
};

export default Footer;
